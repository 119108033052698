export default {
    startLoading(state) {
        state.loading = true
    },
    stopLoading(state) {
        state.loading = false
    },
    setSessionToken(state, token) {
        state.session.token = token
    },
    setSessionRefreshToken(state, refreshToken) {
        state.session.refreshToken = refreshToken
    },
    unsetTokens(state) {
        state.session = {
            token: null,
            refreshToken: null,
            user: null
        }
    },
    setUserData(state, data) {
        state.session.user = data
    },
    setTrip(state, trip) {
        state.trip = trip
    },
    updateCart(state, cart) {
        state.cart = cart
    },
    updateCartAccommodations(state, accommodations) {
        state.cart.accommodations = accommodations
    },
    updateCartOptionals(state, optionals) {
        state.cart.optionals = optionals
    },
    updateCartBoardingPlace(state, boardingPlace) {
        state.cart.boardingPlace = boardingPlace
    },
    updateInstallments(state, installment) {
        state.installments = installment
    },
    resetCart(state) {
        state.cart = {
            coupon: null,
            accommodations: [],
            optionals: [],
            boardingPlace: null
        }
        state.order = null
        state.pixData = null
    },
    setCartId(state, cartId) {
        state.cart.id = cartId
    },
    updateTravelers(state, travelers) {
        state.travelers = travelers
    },
    setAcceptedTerms(state, acceptedTerms) {
        state.acceptedTerms = acceptedTerms
    },
    setPaymentMethod(state, paymentMethod) {
        state.paymentMethod = paymentMethod
    },
    setOrder(state, order) {
        state.order = order
    },
    setPixData(state, pixData) {
        state.pixData = pixData
    }
}
