var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"header"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":"/assets/logo.webp","max-width":"98"}})],1),_c('v-col',{attrs:{"cols":"6","offset":"3"}},[(!['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name))?_c('v-list',{staticClass:"menu",attrs:{"nav":""}},[_c('v-list-item',{attrs:{"href":"https://voudetrip.com.br"}},[_vm._v("Início")]),_c('v-list-item',{attrs:{"href":"https://voudetrip.com.br/destinos"}},[_vm._v("Todos os Destinos")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.user.name.split(' ')[0])+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1):_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,3090139996)},[(_vm.user)?_c('v-list',{staticClass:"dropdown"},[_c('v-list-item',{attrs:{"href":"https://minhaconta.voudetrip.com.br"}},[_vm._v("Minha Conta")]),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Sair")])],1):_c('v-list',{staticClass:"dropdown"},[_c('v-list-item',{attrs:{"href":"https://minhaconta.voudetrip.com.br"}},[_vm._v("Login")])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[(['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name))?_c('v-list',{staticClass:"menu",attrs:{"nav":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,539737131)},[(_vm.user)?_c('v-list',{staticClass:"dropdown"},[_c('v-list-item',{attrs:{"href":"https://minhaconta.voudetrip.com.br"}},[_vm._v("Minha Conta")]),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Sair")])],1):_c('v-list',{staticClass:"dropdown"},[_c('v-list-item',{attrs:{"href":"https://minhaconta.voudetrip.com.br","target":"_blank"}},[_vm._v("Login")])],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name))?_c('v-list',{staticClass:"menu",attrs:{"nav":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,2870098587)},[_c('v-list',{staticClass:"dropdown"},[_c('v-list-item',{attrs:{"href":"https://voudetrip.com.br"}},[_vm._v("Início")]),_c('v-list-item',{attrs:{"href":"https://voudetrip.com.br/destinos"}},[_vm._v("Todos os Destinos")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }